export enum Category {
    NONE,
    DRINKS,
    PASTRIES,
    PANINI,
    BREAD,
    // COFFEE,
    // COOKIE,
    // MUFFINS,
}

export interface IProduct {
    id: number;
    name: string;
    small_img: string[];
    big_img: string[];
    price: string;
    weight: string;
    category: Category;
    ingredients: string;
}

export const productsOld: IProduct[] = [
    {
        id: 1,
        name: 'Plăcintă cu brânză de vaci',
        small_img: ['https://i.ibb.co/LSYSq0j/placinta-branza-vaci-small-ss-down.png', 'https://i.ibb.co/q5zJBRk/placinta-branza-vaci-feliat-small-ss-down.png'],
        big_img: ['https://i.ibb.co/jWfj4W1/placinta-branza-vaci-big-ss-down.png', 'https://i.ibb.co/Nnkx8XX/placinta-branza-vaci-feliat-big-ss-down.png'],
        price: '22lei',
        weight: '180g',
        category: Category.PASTRIES,
        ingredients: 'Apă, făină, sare, brânză de vaci 9%',
    },
    {
        id: 2,
        name: 'Plăcintă cu cartofi',
        small_img: ['https://i.ibb.co/wJFGfqg/placinta-cartof-small-ss-down.png', 'https://i.ibb.co/Q66hczf/placinta-cartof-feliat-small-ss-down.png'],
        big_img: ['https://i.ibb.co/FbvY6pf/placinta-cartof-big-ss-down.png', 'https://i.ibb.co/6yxPy2D/placinta-cartof-feliat-big-ss-down.png'],
        price: '18lei',
        weight: '180g',
        category: Category.PASTRIES,
        ingredients: 'Apă, făină, sare, cartofi, ulei, piper negru',
    },
    {
        id: 3,
        name: 'Plăcintă cu varză',
        small_img: ['https://i.ibb.co/NyqdMQm/placinta-varza-small-ss-down.png'],
        big_img: ['https://i.ibb.co/C8fKQZ8/placinta-varza-big-ss-down.png'],
        price: '18lei',
        weight: '160g',
        category: Category.PASTRIES,
        ingredients: 'Apă, făină, sare, varză proaspătă călită',
    },
    {
        id: 4,
        name: 'Plăcintă cu carne de pui',
        small_img: ['https://i.ibb.co/Tmr4NtN/placinta-pui-small-ss-down.png', 'https://i.ibb.co/x7c8jHY/placinta-pui-feliat-small-ss-down.png'],
        big_img: ['https://i.ibb.co/kgWHCyL/placinta-pui-big-ss-down.png', 'https://i.ibb.co/wd3YL0W/placinta-pui-feliat-big-ss-down.png'],
        price: '25lei',
        weight: '180g',
        category: Category.PASTRIES,
        ingredients: 'Apă, făină, sare, carne de pui, ceapă',
    },
    {
        id: 5,
        name: 'Plăcintă cu mere',
        small_img: ['https://i.ibb.co/jrz9NCx/placinta-mere-small-ss-down.png'],
        big_img: ['https://i.ibb.co/jDTxfKH/placinta-mere-big-ss-down.png'],
        price: '20lei',
        weight: '180g',
        category: Category.PASTRIES,
        ingredients: 'Apă, făină, sare, zahăr, mere proaspete, scorțișoară',
    },
    {
        id: 6,
        name: 'Plăcintă cu vișine',
        small_img: ['https://i.ibb.co/PcxHvr0/placinta-visine-small-ss-down.png'],
        big_img: ['https://i.ibb.co/T8cqnFw/placinta-visine-big-ss-down.png'],
        price: '22lei',
        weight: '180g',
        category: Category.PASTRIES,
        ingredients: 'Apă, făină, sare, zahăr, vișine congelate',
    },
    {
        id: 7,
        name: 'Plăcintă cu dovleac',
        small_img: ['https://i.ibb.co/wz0qWpT/placinta-dovleac-small-ss-down.png'],
        big_img: ['https://i.ibb.co/7W0N8RD/placinta-dovleac-big-ss-down.png'],
        price: '22lei',
        weight: '180g',
        category: Category.PASTRIES,
        ingredients: 'Apă, făină, sare, zahăr, dovleac',
    },
    {
        id: 8,
        name: 'Tartă cu gem',
        small_img: ['https://i.ibb.co/TcPqFXD/tarta-cu-gem-small-ss-down.png'],
        big_img: ['https://i.ibb.co/Y2ZVcNK/tarta-cu-gem-big-ss-down.png'],
        price: '25lei',
        weight: 'N/A',
        category: Category.PASTRIES,
        ingredients: 'Unt 82%, zahăr, sare, făină, smântână, ouă de găină, gem',
    },
    {
        id: 9,
        name: 'Sociniki',
        small_img: ['https://i.ibb.co/xYLqPPn/sociniki-small-ss-down.png'],
        big_img: ['https://i.ibb.co/N7Fd9ZF/sociniki-big-ss-down.png'],
        price: '15lei',
        weight: '100g',
        category: Category.PASTRIES,
        ingredients: 'Unt 82%, zahăr, sare, făină, smântână, ouă de găină, brânză de vaci 9%',
    },
    {
        id: 10,
        name: 'Cornulețe cu vișină',
        small_img: ['https://i.ibb.co/0hVkZC7/cornulete-small-ss-down.png'],
        big_img: ['https://i.ibb.co/mJQh5Sr/cornulete-big-ss-down.png'],
        price: '17lei/4buc',
        weight: '180lei/kg',
        category: Category.PASTRIES,
        ingredients: 'Untură, vin alb, făină, zahăr, sare, vișine congelate, zahăr pudră',
    },
    {
        id: 11,
        name: 'Ruladă cu vișine și ciocolată',
        small_img: ['https://i.ibb.co/3Wf0DpK/cozonac-feliat-small-ss-down.png', 'https://i.ibb.co/F8vMmp3/cozonac-small-ss-down.png'],
        big_img: ['https://i.ibb.co/0msNkHp/cozonac-big-ss-down.png', 'https://i.ibb.co/89qFjsK/cozonac-feliat-big-ss-down.png'],
        price: '65lei',
        weight: '300g',
        category: Category.PASTRIES,
        ingredients: 'Lapte, ouă de găină, făină, unt, ciocolată, zahăr, vișine congelate',
    },
    {
        id: 12,
        name: 'Ruladă cu mac și rahat',
        small_img: ['https://i.ibb.co/mvtDVwQ/rulada-mac-rahat-small-ss-down.png'],
        big_img: ['https://i.ibb.co/8M9XYCk/rulada-mac-rahat-big-ss-down.png'],
        price: '65lei',
        weight: '300g',
        category: Category.PASTRIES,
        ingredients: 'Lapte, ouă de găină, făină, unt, rahat, mac hidratat',
    },
    {
        id: 13,
        name: 'Cozonac cu ciocolată și rahat',
        small_img: [],
        big_img: [],
        price: 'N/A',
        weight: 'N/A',
        category: Category.PASTRIES,
        ingredients: 'Lapte, ouă de găină, făină, unt, ciocolată, mac hidratat',
    },
    {
        id: 14,
        name: 'Pâine (cu maia) și semințe',
        small_img: ['https://i.ibb.co/cgzdZzS/paine-small-ss-down.png'],
        big_img: ['https://i.ibb.co/3NkrpKD/paine-big-ss-down.png', 'https://i.ibb.co/WK58kcH/paine-taiata-big-ss-down.png'],
        price: '30lei',
        weight: '600g',
        category: Category.BREAD,
        ingredients: 'Maia, făină integrală, semințe de floarea soarelui',
    },
    {
        id: 15,
        name: 'Brioșe cu ciocolată',
        small_img: [],
        big_img: [],
        price: '20lei',
        weight: '180g',
        category: Category.PASTRIES,
        ingredients: 'Făină, ulei, ouă de găină, chefir, zahăr, sare, praf de copt, ciocolată neagră, cacao',
    },
    {
        id: 16,
        name: 'Brioșă cu vanilie',
        small_img: ['https://i.ibb.co/ZX2P0W2/briosa-cu-vanilie-small-ss-down.png'],
        big_img: ['https://i.ibb.co/S79gPNP/briosa-cu-vanilie-big-ss-down.png'],
        price: '20lei',
        weight: 'N/A',
        category: Category.PASTRIES,
        ingredients: 'Făină, ulei, ouă de găină, chefir, zahăr vanilat, sare, praf de copt',
    },
    {
        id: 17,
        name: 'Crenvușcă în aluat',
        small_img: ['https://i.ibb.co/D876P21/covridog-small-ss-down.png'],
        big_img: ['https://i.ibb.co/JtFFNTs/covridog-big-ss-down.png'],
        price: '20lei',
        weight: '130g',
        category: Category.PASTRIES,
        ingredients: 'Făină, ulei, apă, sare, crenvușcă',
    },
    {
        id: 18,
        name: 'Biscuiți cu ovăz, răchițele și semințe',
        small_img: ['https://i.ibb.co/s6WNzT5/biscuiti-cu-ovaz-small-ss-down.png'],
        big_img: ['https://i.ibb.co/GMgnR9x/biscuiti-cu-ovaz-big-ss-down.png', 'https://i.ibb.co/SRFGhb3/biscuiti-cu-ovaz-rup-big-ss-down.png'],
        price: '15lei',
        weight: 'N/A',
        category: Category.PASTRIES,
        ingredients: 'Fulgi de ovăz, făină, ouă de găină, zahăr, sare, răchițele hidratate, semințe de floarea soarelui, unt de 82%',
    },
    {
        id: 19,
        name: 'Caesar Panini',
        small_img: ['https://i.ibb.co/BPMj8vR/caesar-panini-small-ss-down.png'],
        big_img: ['https://i.ibb.co/jL0JMhz/caesar-panini-big-ss-down.png'],
        price: '32lei',
        weight: '200g',
        category: Category.PANINI,
        ingredients: 'Baghetă franceză, sos caesar, cașcaval, piept de pui afumat, castraveți, frunze de salată, roșii',
    },
    {
        id: 20,
        name: 'Italian Panini',
        small_img: [],
        big_img: [],
        price: '35lei',
        weight: 'N/A',
        category: Category.PANINI,
        ingredients: 'Baghetă franceză, cremă de brânză, cașcaval, prosciuto crudo, castraveți',
    },
    {
        id: 21,
        name: 'Fresh Panini',
        small_img: ['https://i.ibb.co/8m7XcPc/fresh-panini-small-ss-down.png'],
        big_img: ['https://i.ibb.co/Pj3xF5z/fresh-panini-big-ss-down.png'],
        price: '30lei',
        weight: '200g',
        category: Category.PANINI,
        ingredients: 'Baghetă franceză, maioneză, cașcaval, șuncă, porumb, castraveți',
    },
    {
        id: 22,
        name: 'Brownie',
        small_img: ['https://i.ibb.co/grtKSMv/brownie-small-ss-down.png'],
        big_img: ['https://i.ibb.co/8bjdYH3/brownie-big-ss-down.png'],
        price: '35lei',
        weight: '100g',
        category: Category.PASTRIES,
        ingredients: 'Unt 82%, ciocolată neagră, cacao, făină, ouă de găină, glazură de ciocolată, fulgi de migdale',
    },
    {
        id: 23,
        name: 'Babă neagră',
        small_img: ['https://i.ibb.co/yFYnvkt/baba-neagra-small-ss.png'],
        big_img: ['https://i.ibb.co/pzvbM7G/baba-neagra-big-ss.png'],
        price: '250lei/kg',
        weight: 'N/A',
        category: Category.PASTRIES,
        ingredients: 'Lapte, chefir, ouă de găină, sodă alimentară, țuică, făină',
    },
    {
        id: 24,
        name: 'Croissant cu nutella',
        small_img: [],
        big_img: [],
        price: '30lei',
        weight: '100g',
        category: Category.PASTRIES,
        ingredients: 'Făină albă de grâu de calitate superioară, apă, lapte 3,5%, zahăr, grăsime premium cu maia de grâu nehidrogenată, sare iodată, nutella',
    },
    {
        id: 25,
        name: 'Croissant cu mascarpone',
        small_img: ['https://i.ibb.co/T4QP2dd/croissant-cu-mascarpone-small-ss-down.png'],
        big_img: ['https://i.ibb.co/YfgbzmX/croissant-cu-mascarpone-big-ss-down.png'],
        price: '35lei',
        weight: '110g',
        category: Category.PASTRIES,
        ingredients: 'Făină albă de grâu de calitate superioară, apă, lapte 3,5%, zahăr, grăsime premium cu maia de grâu nehidrogenată, sare iodată, frisca lichida 33%, ciocolatà alba, mascarpone',
    },
    {
        id: 26,
        name: 'Croissant cu unt',
        small_img: ['https://i.ibb.co/tm7nGnb/croissant-vanilie-small-ss-down.png'],
        big_img: ['https://i.ibb.co/K7GvD0q/croissant-vanilie-big-ss-down.png'],
        price: '20lei',
        weight: '65g',
        category: Category.PASTRIES,
        ingredients: 'Făină albă de grâu de calitate superioară, apă, lapte 3,5%, zahăr, grăsime premium cu maia de grâu nehidrogenată, sare iodată',
    },
    {
        id: 27,
        name: 'Foietaj cu cașcaval',
        small_img: [],
        big_img: [],
        price: '20lei',
        weight: '165g',
        category: Category.PASTRIES,
        ingredients: 'Făină albă de grâu de calitate superioară, apă, zahăr, grăsime premium cu maia de grâu nehidrogenată, sare iodată, cașcaval',
    },
    {
        id: 28,
        name: 'Foietaj cu scorțișoară',
        small_img: [],
        big_img: [],
        price: '15lei',
        weight: '165g',
        category: Category.PASTRIES,
        ingredients: 'Făină albă de grâu de calitate superioară, apă, zahăr, grăsime premium cu maia de grâu nehidrogenată, sare iodată, scorțișoară',
    },
    {
        id: 29,
        name: 'Biscuiți cu lămaie și nuci',
        small_img: ['https://i.ibb.co/3m3b8LQ/biscuiti-lamie-nuci-small-ss-down.png'],
        big_img: ['https://i.ibb.co/Y7QfmQZ/biscuiti-lamie-nuci-big-ss-down.png', 'https://i.ibb.co/6bB9mY1/biscuiti-lamie-nuci-tower-big-ss-down.png'],
        price: '15lei',
        weight: 'N/A',
        category: Category.PASTRIES,
        ingredients: 'Făină, ouă de găină, zahăr, sare, lămaie, nuci, unt de 82%',
    },
    // Bauturi calde
    {
        id: 100,
        name: 'Americano',
        small_img: [],
        big_img: [],
        price: '22lei',
        weight: '',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 101,
        name: 'Cappucino',
        small_img: [],
        big_img: [],
        price: '25lei',
        weight: '',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 102,
        name: 'Ceai',
        small_img: [],
        big_img: [],
        price: '15lei',
        weight: '',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 103,
        name: 'Ceai meal time',
        small_img: [],
        big_img: [],
        price: '30lei',
        weight: '',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 104,
        name: 'Ciocolată caldă',
        small_img: [],
        big_img: [],
        price: '30lei',
        weight: '',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 105,
        name: 'Double cappucino',
        small_img: [],
        big_img: [],
        price: '45lei',
        weight: '',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 106,
        name: 'Espresso',
        small_img: [],
        big_img: [],
        price: '20lei',
        weight: '',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 107,
        name: 'Espresso machiato',
        small_img: [],
        big_img: [],
        price: '22lei',
        weight: '',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 108,
        name: 'Latte',
        small_img: [],
        big_img: [],
        price: '30lei',
        weight: '',
        category: Category.DRINKS,
        ingredients: '',
    },
    // Bauturi reci
    {
        id: 200,
        name: 'Apă carbogazoasă',
        small_img: [],
        big_img: [],
        price: '15lei',
        weight: '500ml',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 201,
        name: 'Apă plată',
        small_img: [],
        big_img: [],
        price: '15lei',
        weight: '500ml',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 202,
        name: 'Cappy',
        small_img: [],
        big_img: [],
        price: '20lei',
        weight: '',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 203,
        name: 'Coca Cola',
        small_img: [],
        big_img: [],
        price: '17lei',
        weight: '',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 204,
        name: 'Fanta',
        small_img: [],
        big_img: [],
        price: '17lei',
        weight: '',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 205,
        name: 'Sprite',
        small_img: [],
        big_img: [],
        price: '17lei',
        weight: '',
        category: Category.DRINKS,
        ingredients: '',
    },
]

const products: IProduct[] = [
    {
        id: 1,
        name: 'Plăcintă cu brânză de vaci',
        small_img: ['/images/products/placinta_branza_vaci/placinta_branza_vaci_small_ss_down.png'],
        big_img: ['/images/products/placinta_branza_vaci/placinta_branza_vaci_big_ss_down.png', '/images/products/placinta_branza_vaci/placinta_branza_vaci_feliat_big_ss_down.png'],
        price: '24lei',
        weight: '180g',
        category: Category.PASTRIES,
        ingredients: 'Apă, făină, sare, brânză de vaci 9%',
    },
    {
        id: 2,
        name: 'Plăcintă cu cartofi',
        small_img: ['/images/products/placinta_cartof/placinta_cartof_small_ss_down.png'],
        big_img: ['/images/products/placinta_cartof/placinta_cartof_big_ss_down.png', '/images/products/placinta_cartof/placinta_cartof_feliat_big_ss_down.png'],
        price: '20lei',
        weight: '180g',
        category: Category.PASTRIES,
        ingredients: 'Apă, făină, sare, cartofi, ulei, piper negru',
    },
    {
        id: 3,
        name: 'Plăcintă cu varză',
        small_img: ['/images/products/placinta_varza/placinta_varza_small_ss_down.png'],
        big_img: ['/images/products/placinta_varza/placinta_varza_big_ss_down.png'],
        price: '20lei',
        weight: '160g',
        category: Category.PASTRIES,
        ingredients: 'Apă, făină, sare, varză proaspătă călită',
    },
    {
        id: 4,
        name: 'Plăcintă cu carne de pui',
        small_img: ['/images/products/placinta_pui/placinta_pui_small_ss_down.png'],
        big_img: ['/images/products/placinta_pui/placinta_pui_big_ss_down.png', '/images/products/placinta_pui/placinta_pui_feliat_big_ss_down.png'],
        price: '27lei',
        weight: '180g',
        category: Category.PASTRIES,
        ingredients: 'Apă, făină, sare, carne de pui, ceapă',
    },
    {
        id: 5,
        name: 'Plăcintă cu mere',
        small_img: ['/images/products/placinta_mere/placinta_mere_small_ss_down.png'],
        big_img: ['/images/products/placinta_mere/placinta_mere_big_ss_down.png'],
        price: '22lei',
        weight: '180g',
        category: Category.PASTRIES,
        ingredients: 'Apă, făină, sare, zahăr, mere proaspete, scorțișoară',
    },
    {
        id: 6,
        name: 'Plăcintă cu vișine',
        small_img: ['/images/products/placinta_visine/placinta_visine_small_ss_down.png'],
        big_img: ['/images/products/placinta_visine/placinta_visine_big_ss_down.png'],
        price: '25lei',
        weight: '180g',
        category: Category.PASTRIES,
        ingredients: 'Apă, făină, sare, zahăr, vișine congelate',
    },
    {
        id: 7,
        name: 'Plăcintă cu dovleac',
        small_img: ['/images/products/placinta_dovleac/placinta_dovleac_small_ss_down.png'],
        big_img: ['/images/products/placinta_dovleac/placinta_dovleac_big_ss_down.png'],
        price: '24lei',
        weight: '180g',
        category: Category.PASTRIES,
        ingredients: 'Apă, făină, sare, zahăr, dovleac',
    },
    {
        id: 8,
        name: 'Tartă cu gem',
        small_img: ['/images/products/tarta_cu_gem/tarta_cu_gem_small_ss_down.png'],
        big_img: ['/images/products/tarta_cu_gem/tarta_cu_gem_big_ss_down.png'],
        price: '28lei',
        weight: 'N/A',
        category: Category.PASTRIES,
        ingredients: 'Unt 82%, zahăr, sare, făină, smântână, ouă de găină, gem',
    },
    {
        id: 9,
        name: 'Sociniki',
        small_img: ['/images/products/sociniki/sociniki_small_ss_down.png'],
        big_img: ['/images/products/sociniki/sociniki_big_ss_down.png'],
        price: '18lei',
        weight: '100g',
        category: Category.PASTRIES,
        ingredients: 'Unt 82%, zahăr, sare, făină, smântână, ouă de găină, brânză de vaci 9%',
    },
    {
        id: 10,
        name: 'Cornulețe cu vișină',
        small_img: ['/images/products/cornulete/cornulete_small_ss_down.png'],
        big_img: ['/images/products/cornulete/cornulete_big_ss_down.png'],
        price: '20lei/4buc',
        weight: '200lei/kg',
        category: Category.PASTRIES,
        ingredients: 'Untură, vin alb, făină, zahăr, sare, vișine congelate, zahăr pudră',
    },
    {
        id: 11,
        name: 'Ruladă cu vișine și ciocolată',
        small_img: ['/images/products/cozonac/cozonac_feliat_small_ss_down.png'],
        big_img: ['/images/products/cozonac/cozonac_big_ss_down.png', '/images/products/cozonac/cozonac_feliat_big_ss_down.png'],
        price: '85lei',
        weight: '300g',
        category: Category.PASTRIES,
        ingredients: 'Lapte, ouă de găină, făină, unt, ciocolată, zahăr, vișine congelate',
    },
    {
        id: 12,
        name: 'Ruladă cu mac și rahat',
        small_img: ['/images/products/rulada_mac/rulada_mac_rahat_small_ss_down.png'],
        big_img: ['/images/products/rulada_mac/rulada_mac_rahat_big_ss_down.png'],
        price: '85lei',
        weight: '300g',
        category: Category.PASTRIES,
        ingredients: 'Lapte, ouă de găină, făină, unt, rahat, mac hidratat',
    },
    {
        id: 13,
        name: 'Pâine (cu maia) și semințe',
        small_img: ['/images/products/paine/paine_small_ss_down.png'],
        big_img: ['/images/products/paine/paine_big_ss_down.png', '/images/products/paine/paine_taiata_big_ss_down.png'],
        price: '40lei',
        weight: '600g',
        category: Category.BREAD,
        ingredients: 'Maia, făină integrală, semințe de floarea soarelui',
    },
    {
        id: 14,
        name: 'Crenvușcă în aluat',
        small_img: ['/images/products/covridog/covridog_small_ss_down.png'],
        big_img: ['/images/products/covridog/covridog_big_ss_down.png'],
        price: '22lei',
        weight: '130g',
        category: Category.PASTRIES,
        ingredients: 'Făină, ulei, apă, sare, crenvușcă',
    },
    {
        id: 15,
        name: 'Biscuiți cu ovăz, răchițele și semințe',
        small_img: ['/images/products/biscuiti_ovaz/biscuiti_cu_ovaz_small_ss_down.png'],
        big_img: ['/images/products/biscuiti_ovaz/biscuiti_cu_ovaz_big_ss_down.png', '/images/products/biscuiti_ovaz/biscuiti_cu_ovaz_rup_big_ss_down.png'],
        price: '15lei',
        weight: 'N/A',
        category: Category.PASTRIES,
        ingredients: 'Fulgi de ovăz, făină, ouă de găină, zahăr, sare, răchițele hidratate, semințe de floarea soarelui, unt de 82%',
    },
    {
        id: 16,
        name: 'Caesar Panini',
        small_img: ['/images/products/caesar_panini/caesar_panini_small_ss_down.png'],
        big_img: ['/images/products/caesar_panini/caesar_panini_big_ss_down.png'],
        price: '40lei',
        weight: '200g',
        category: Category.PANINI,
        ingredients: 'Baghetă franceză, sos caesar, cașcaval, piept de pui afumat, castraveți, frunze de salată, roșii',
    },
    {
        id: 17,
        name: 'Italian Panini',
        small_img: ['/images/products/panini_italian/panini_italian_ss_small_down.png'],
        big_img: ['/images/products/panini_italian/panini_italian_ss_big_down.png'],
        price: '45lei',
        weight: '200g',
        category: Category.PANINI,
        ingredients: 'Baghetă franceză, cremă de brânză, cașcaval, prosciuto crudo, castraveți proaspeți',
    },
    {
        id: 18,
        name: 'Fresh Panini',
        small_img: ['/images/products/fresh_panini/fresh_panini_small_ss_down.png'],
        big_img: ['/images/products/fresh_panini/fresh_panini_big_ss_down.png'],
        price: '38lei',
        weight: '200g',
        category: Category.PANINI,
        ingredients: 'Baghetă franceză, maioneză, sunca de curcan, cascaval, rosii, castraveti proaspeți',
    },
    {
        id: 19,
        name: 'Panini Taranesc',
        small_img: [],
        big_img: [],
        price: '38lei',
        weight: '200g',
        category: Category.PANINI,
        ingredients: 'Baghetă franceză, maioneză, salam uscat, rosii, castraveti proaspeti',

    },
    {
        id: 20,
        name: 'Brownie',
        small_img: ['/images/products/brownie/brownie_small_ss_down.png'],
        big_img: ['/images/products/brownie/brownie_big_ss_down.png'],
        price: '36lei',
        weight: '100g',
        category: Category.PASTRIES,
        ingredients: 'Unt 82%, ciocolată neagră, cacao, făină, ouă de găină, glazură de ciocolată, fulgi de migdale',
    },
    {
        id: 21,
        name: 'Croissant cu nutella',
        small_img: [],
        big_img: [],
        price: '30lei',
        weight: '100g',
        category: Category.PASTRIES,
        ingredients: 'Făină albă de grâu de calitate superioară, apă, lapte 3,5%, zahăr, grăsime premium cu maia de grâu nehidrogenată, sare iodată, nutella',
    },
    {
        id: 22,
        name: 'Croissant cu mascarpone',
        small_img: ['/images/products/croissant_mascarpone/croissant_cu_mascarpone_small_ss_down.png'],
        big_img: ['/images/products/croissant_mascarpone/croissant_cu_mascarpone_big_ss_down.png'],
        price: '35lei',
        weight: '110g',
        category: Category.PASTRIES,
        ingredients: 'Făină albă de grâu de calitate superioară, apă, lapte 3,5%, zahăr, grăsime premium cu maia de grâu nehidrogenată, sare iodată, frisca lichida 33%, ciocolatà alba, mascarpone',
    },
    {
        id: 23,
        name: 'Croissant cu unt',
        small_img: ['/images/products/croissant_vanilie/croissant_vanilie_small_ss_down.png'],
        big_img: ['/images/products/croissant_vanilie/croissant_vanilie_big_ss_down.png'],
        price: '20lei',
        weight: '65g',
        category: Category.PASTRIES,
        ingredients: 'Făină albă de grâu de calitate superioară, apă, lapte 3,5%, zahăr, grăsime premium cu maia de grâu nehidrogenată, sare iodată',
    },
    {
        id: 24,
        name: 'Galeta cu fructe de sezon',
        small_img: ['/images/products/galeta_fructe/galeta_fructe_ss_small_down.png'],
        big_img: ['/images/products/galeta_fructe/galeta_fructe_ss_big_down.png'],
        price: '40lei',
        weight: '165g',
        category: Category.PASTRIES,
        ingredients: 'Făină albă de grâu de calitate superioară,unt, ou, smântână, zahar, fructe de sezon'
    },
    {
        id: 25,
        name: 'Pizza',
        small_img: [],
        big_img: [],
        price: '28lei',
        weight: '165g',
        category: Category.PASTRIES,
        ingredients: 'Făină albă de grâu de calitate superioară, apă,drojdie, ketchup, maioneza, piept de pui afumat, roșii, oregano',
    },
    {
        id: 26,
        name: 'Tort Napoleon',
        small_img: ['/images/products/tort_napoleon/tort_napoleon_ss_small_down.png'],
        big_img: ['/images/products/tort_napoleon/tort_napoleon_ss_big_down.png'],
        price: '60lei',
        weight: '150g',
        category: Category.PASTRIES,
        ingredients: 'Făină, ouă de găină, unt de 82%, lapte, vanilie, frișca lichida, zahar',
    },
    {
        id: 27,
        name: 'Tort Medovik',
        small_img: ['/images/products/tort_medovik/tort_medovik_ss_small_down.png'],
        big_img: ['/images/products/tort_medovik/tort_medovik_ss_big_down.png'],
        price: '60lei',
        weight: '150g',
        category: Category.PASTRIES,
        ingredients: 'Miere, faina, praf de copt, smântâna, frișca lichida, vișine in suc propriu, zahar, unt, zahar pudra, nuci',
    },
    {
        id: 28,
        name: 'Tort Spartak',
        small_img: ['/images/products/tort_spartak/tort_spartak_ss_small_down.png'],
        big_img: ['/images/products/tort_spartak/tort_spartak_ss_big_down.png'],
        price: '60lei',
        weight: '150g',
        category: Category.PASTRIES,
        ingredients: 'faina, cacao, unt, zahar, vanilie, frisca lichida, oua, lapte'
    },
    {
        id: 29,
        name: 'Kartoska',
        small_img: ['/images/products/kartoska/kartoska_ss_small_down.png'],
        big_img: ['/images/products/kartoska/kartoska_ss_big_down.png'],
        price: '35lei',
        weight: '150g',
        category: Category.PASTRIES,
        ingredients: 'lapte, oua, smântâna, faina cacao, arahide coapte, zahar, unt, ciocolata neagra'
    },
    // Bauturi calde
    {
        id: 100,
        name: 'Americano',
        small_img: [],
        big_img: [],
        price: '23lei',
        weight: '190ml',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 101,
        name: 'Cappucino',
        small_img: [],
        big_img: [],
        price: '25lei',
        weight: '236ml',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 102,
        name: 'Ceai',
        small_img: [],
        big_img: [],
        price: '17lei',
        weight: '473ml',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 104,
        name: 'Ciocolată caldă',
        small_img: [],
        big_img: [],
        price: '32lei',
        weight: '236ml',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 105,
        name: 'Double cappucino',
        small_img: [],
        big_img: [],
        price: '46lei',
        weight: '473ml',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 106,
        name: 'Espresso',
        small_img: [],
        big_img: [],
        price: '22lei',
        weight: '50ml',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 107,
        name: 'Espresso machiato',
        small_img: [],
        big_img: [],
        price: '25lei',
        weight: '60ml',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 108,
        name: 'Latte',
        small_img: [],
        big_img: [],
        price: '31lei',
        weight: '473ml',
        category: Category.DRINKS,
        ingredients: '',
    },
    // Bauturi reci
    {
        id: 200,
        name: 'Apă carbogazoasă',
        small_img: ['/images/drinks/apa_carbogazoasa/apa_carbogazoasa_small.png'],
        big_img: ['/images/drinks/apa_carbogazoasa/apa_carbogazoasa_big.png'],
        price: '17lei',
        weight: '500ml',
        category: Category.DRINKS,
        ingredients: ''
    },
    {
        id: 201,
        name: 'Apă plată',
        small_img: ['/images/drinks/apa_plata/apa_plata_small.png'],
        big_img: ['/images/drinks/apa_plata/apa_plata_big.png'],
        price: '17lei',
        weight: '500ml',
        category: Category.DRINKS,
        ingredients: ''
    },
    {
        id: 202,
        name: 'Cappy',
        small_img: ['/images/drinks/cappy/cappy_small.png'],
        big_img: ['/images/drinks/cappy/cappy_big.png'],
        price: '23lei',
        weight: '330ml',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 203,
        name: 'Coca Cola',
        small_img: ['/images/drinks/coca_cola/coca_cola_small.png'],
        big_img: ['/images/drinks/coca_cola/coca_cola_big.png'],
        price: '19lei',
        weight: '500ml',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 204,
        name: 'Fanta',
        small_img: ['/images/drinks/fanta/fanta_small.png'],
        big_img: ['/images/drinks/fanta/fanta_big.png'],
        price: '19lei',
        weight: '500ml',
        category: Category.DRINKS,
        ingredients: '',
    },
    {
        id: 205,
        name: 'Sprite',
        small_img: ['/images/drinks/sprite/sprite_small.png'],
        big_img: ['/images/drinks/sprite/sprite_big.png'],
        price: '20lei',
        weight: '500ml',
        category: Category.DRINKS,
        ingredients: '',
    },
]

export default products;